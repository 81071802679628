import axios from 'axios'
import { defineStore } from 'pinia'


export const useAuthStore = defineStore({
  id: 'auth',


  state: () => ({

    user: null,
    status: '',
    errorToken: '',
    error: ''
  }),
  actions: {
    login (user) {
      return new Promise((resolve, reject) => {
        axios.post('auth/', {
          username: user.username,
          password: user.password,
        })
            .then(resp => {
              this.status = 'success'
              this.user = resp.data
              resolve(resp)
            })
            .catch(err => {
              if (err.response) {
                this.error = err.response.data
              }
              reject(err)
            })
      })
    },
  },

  getters:{
    isLoggedIn: state => {
      return state.status
    },
    userGetters: state => {
      return state.user
    },
    errorGetters: state => {
      return state.error
    },
    errorTokenGetters: state => {
      return state.errorToken
    }
  }
})



