import { createRouter, createWebHistory } from 'vue-router'
import {useAuthStore} from "../stores/auth";
import {useApiSettingStore} from "../stores/apiSettings";


const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue')
  },

  {
    path: '/operators',
    name: 'operators',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Operators" */ '../views/OperatorsView.vue')
  },
  {
    path: '/worksites',
    name: 'worksites',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Worksites" */ '../views/WorksitesView.vue')
  },

  {
    path: '/supports',
    name: 'supports',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Supports" */ '../views/SupportsView.vue')
  },

  {
    path: '/jobs',
    name: 'jobs',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/JobsView.vue')
  },
  {
    path: '/dpis',
    name: 'dpis',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/DpiView.vue')
  },
  // {
  //   path: '/sensors',
  //   name: 'sensors',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () => import(/* webpackChunkName: "Jobs" */ '../views/SensorView.vue')
  // },
  {
    path: '/dpis/:id',
    name: 'detailDpi',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/DetailDpi.vue')
  },
  {
    path: '/type-dpi',
    name: 'typeDpi',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/TypeDpi.vue')
  },
  {
    path: '/type-dpi/:id',
    name: 'detailTypeDpi',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/DetailTypeDpi.vue')
  },
  {
    path: '/type-job',
    name: 'typeJob',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/TypeJob.vue')
  },
  {
    path: '/type-job/:id',
    name: 'detailTypeJob',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/DetailTypeJob.vue')
  },
  // {
  //   path: '/sensors/:id',
  //   name: 'detailSensor',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () => import(/* webpackChunkName: "Jobs" */ '../views/DetailSensor.vue')
  // },

  {
    path: '/operators/:id',
    name: 'detailOperator',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "DetailOperator" */ '../views/DetailOperator.vue')
  },

  {
    path: '/worksites/:id',
    name: 'detailWorksite',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "DetailWorksite" */ '../views/DetailWorksite.vue')
  },

  {
    path: '/jobs/:id',
    name: 'detailJob',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "DetailJob" */ '../views/DetailJob.vue')
  },

  {
    path: '/supports/:id',
    name: 'detailSupport',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "DetailJob" */ '../views/DetailSupport.vue')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "DetailJob" */ '../views/ResetPassword.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})




router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (useAuthStore().isLoggedIn !== 'success') {
      next({
        path: '/login'
      })
    } else {
      useApiSettingStore().overlayModal = false
      next()
    }
  }
  else {
    next()
  }
})




export default router
