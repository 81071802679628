<template>
  <div id="app" :class="{overflow_hidden: overlayModal}">
    <router-view></router-view>
    <b-alert variant="danger" :show="store.errorNetworkGetters">Connessione assente</b-alert>
    <div v-if="overlayModal" class="overlay"></div>
  </div>
</template>

<script>

import {useApiSettingStore} from "./stores/apiSettings";

export default {
  name: 'App',
  setup(){

    let store = useApiSettingStore()
    return{
      store
    }
  },
  created() {
    const storeApiSettings = useApiSettingStore()
    storeApiSettings.overlayModal = false
    storeApiSettings.overlayPopup = false
    storeApiSettings.typePopupCustom = ''
    if ((window.innerWidth < 767.98) || (screen.orientation.angle > 0)){
      this.changeVariables()
    } else {
      this.resetVariables()
    }
  },
  computed:{
    overlayModal(){
      return useApiSettingStore().overlayModalGetters
    }
  },

  beforeUnmount() {
    useApiSettingStore().errorNetwork = false
  },
  methods:{
    changeVariables(){
      document.documentElement.style.setProperty('--xx-small', '1.3vmax');
      document.documentElement.style.setProperty('--x-small', '1.7vmax');
      document.documentElement.style.setProperty('--small', '2vmax');
      document.documentElement.style.setProperty('--medium', '3vmax');
      document.documentElement.style.setProperty('--large', '4vmax');
      document.documentElement.style.setProperty('--x-large', '5.5vmax');
      document.documentElement.style.setProperty('--xx-large', '7.5vmax');

      document.documentElement.style.setProperty('--height-form-control', '5vmax');
      document.documentElement.style.setProperty('--height-img-form', '2.5vmax');
      document.documentElement.style.setProperty('--width-img-form', '2.5vmax');

      document.documentElement.style.setProperty('--line-height-small', '2.1vmax');
      document.documentElement.style.setProperty('--line-height-medium', '2.6vmax');
      document.documentElement.style.setProperty('--line-height-large', '3vmax');
      document.documentElement.style.setProperty('--line-height-x-large', '5.5vmax');


      document.documentElement.style.setProperty('--width-img-circle', '5vmax');
      document.documentElement.style.setProperty('--width-img-in-circle', '2.5vmax');
      document.documentElement.style.setProperty('--height-img-circle', '5vmax');
      document.documentElement.style.setProperty('--height-img-in-circle', '2.5vmax');
    },
    resetVariables(){
      document.documentElement.style.setProperty('--xx-small', '1.3vmin');
      document.documentElement.style.setProperty('--x-small', '1.7vmin');
      document.documentElement.style.setProperty('--small', '2vmin');
      document.documentElement.style.setProperty('--medium', '3vmin');
      document.documentElement.style.setProperty('--large', '4vmin');
      document.documentElement.style.setProperty('--x-large', '5.5vmin');
      document.documentElement.style.setProperty('--xx-large', '7.5vmin');

      document.documentElement.style.setProperty('--height-form-control', '5vmin');
      document.documentElement.style.setProperty('--height-img-form', '2.5vmin');
      document.documentElement.style.setProperty('--width-img-form', '2.5vmin');

      document.documentElement.style.setProperty('--line-height-small', '2.1vmin');
      document.documentElement.style.setProperty('--line-height-medium', '2.6vmin');
      document.documentElement.style.setProperty('--line-height-large', '3vmin');
      document.documentElement.style.setProperty('--line-height-x-large', '5.5vmin');

      document.documentElement.style.setProperty('--width-img-circle', '5vmin');
      document.documentElement.style.setProperty('--width-img-in-circle', '2.5vmin');
      document.documentElement.style.setProperty('--height-img-circle', '5vmin');
      document.documentElement.style.setProperty('--height-img-in-circle', '2.5vmin');
    }
  },
  mounted() {
    window.addEventListener('resize', (e) => {
      if ((e.target.innerWidth < 991.98) || (screen.orientation.angle > 0)){
        this.changeVariables()
      } else {
        this.resetVariables()
      }
    })
  }
}
</script>

<style>
.alert{
  position: fixed!important;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}
#app{
  height: 100vh;
}
.overflow_hidden{
  overflow: hidden;
}
</style>
