import { createApp } from 'vue'
import App from './App.vue'

import BootstrapVue3 from 'bootstrap-vue-3'

import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import VuePapaParse from 'vue-papa-parse'
import axios from "axios";

import { createPinia } from 'pinia'
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'

import router from './router'
import mitt from 'mitt';   // Import mitt
import { useApiSettingStore } from "./stores/apiSettings";
import {useAuthStore} from "./stores/auth";

const emitter = mitt();


const pinia = createPinia()

axios.defaults.baseURL = 'https://api.fvworks.it/'
axios.defaults.headers.common['Accept-Language'] = 'it'

axios.interceptors.response.use(undefined, function (error) {
    if (error.response){
        if (error.response.status === 401) {
            useAuthStore().$reset()
            let string_split
            Object.keys(error.response.data).forEach(el => {
                string_split = el.split('-')
                if (string_split[1] === 'expired_token') {
                    useAuthStore().errorToken = 'Sessione scaduta'
                } else if (string_split[1] === 'invalid_token') {
                    useAuthStore().errorToken = 'Rieffettuare il login'
                } else if (el === 'code-invalid_code') {
                    useAuthStore().errorToken = error.response.data['code-invalid_code']
                } else if (el === 'authorization-user_inactive') {
                    useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                }
            })
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    if (error.message === 'Network Error') {
        useApiSettingStore().overlayModal = false
        useApiSettingStore().errorNetwork = true
    }
    return Promise.reject(error);
})

const DATE_RANGE_CHOICES = ['m', 'w'] //  ('m', 'month'), ('w', 'week') CHANGE the same setting in django settings module

export const DEFAULT_REPORT_DATE_RANGE = DATE_RANGE_CHOICES[0]

export function setStickyTop(control, thead) {
    if (control && thead) {
        control.style.top = useApiSettingStore().heightHeaderBar + 'px'
        if (thead !== null) {
            let d = control.offsetHeight
            d = d + useApiSettingStore().heightHeaderBar
            thead.style.top = d + 'px'
        }
    }
}


const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(router)
app.use(VuePapaParse)
app.use(BootstrapVue3)
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.mount('#app')